import React, { Component, Fragment } from 'react'
import {
  Helpers, Base, Routes, history, Validation,
} from 'utils'
import { Button } from 'components'
import { Router, Route, Switch } from 'react-router-dom'
// import hero from 'images/hero.jpg'
import anotherHero from 'images/hero-img.png'
import heroPortfolio from 'images/hero-portfolio-again.png'
import statisticsHero from 'images/statistics-hero.png'
import revenueHero from 'images/revenue-hero.png'
import marvlous from 'images/marvlous.png'
import marvlousWhite from 'images/marvlous-white.png'
import InputRange from 'react-input-range'
import Styles from './Styles'
import Support from './Support/Support'

import 'react-input-range/lib/css/index.css'

class Index extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={Routes.INDEX}
            render={props => (
              <Support />
            )}
          />
        </Switch>
      </Router>
    )
  }
}

export default Index
