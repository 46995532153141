import { css } from 'emotion'

const background = css`
  background: #1181eb;
  margin-top: -200px;
  padding-top: 250px;
  color: #fff;
  h3{
    font-weight: bold;
  }
`

const contentWrapper = css`
  button{
    width: auto;
    display: inline-flex;
    margin-top: 20px;
  }
  h1{
    font-weight: bold;
  }
`

const heroImage = css`
  img{
    box-shadow: 0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    border-radius: 4px;
  }
`

const browserWrapper = css`
  position: relative;
  > div {
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    background: #edf1f5;
    width: 100%;
    height: auto;
    padding: 9px 7px;
    > div {
      width: 93%;
      height: 15px;
      background: #fff;
      border-radius: 2px;
      margin-left: 10px;
    }
    span{
      width: 10px;
      height: 10px;
      display: inline-flex;
      border-radius: 50%;
      margin-left: 3px;
      margin-right: 3px;
      &:first-of-type{
        margin-left: 0px;
        background: #FF6057;
      }
      &:nth-child(2){
        background: #FFC12E;
      }
      &:last-of-type{
        margin-right: 0px;
        background: #28CA40;
      }
    }
  }
`

const patternPlace = css`
  span{
    padding: 20px;
    border-radius: 2px;
    box-shadow:0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    color: #1181eb;
    display: inline-flex;
    background: #fff;
    position:relative;
    &:first-of-type{
      i{
        font-size: 10px;
      }
    }
    &:nth-child(2){
      bottom: -75px;
      right: -25px;
      i{
        font-size: 36px;
      }
    }
    &:nth-child(3){
      right: -75px;
      top: 25px;
      i{
        font-size: 16px;
      }
    }
    &:nth-child(4){
      left: -50px;
      top: 50px;
    }
    &:last-of-type{
      bottom: -100px;
      right: -75px;
    }
    i{
      font-size: 26px;
    }
  }
`

const portfolio = css`
  h3, h1{
    font-weight: bold;
  }
  img{
    box-shadow: 0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    border-radius: 4px;
  }
`

const navbarBrand = css`
  &:hover{
    text-decoration:none;
  }
  span{
    font-weight: bold;
    color: #1181eb;
    margin-left: 3px;
  }
  img{
    width:40px;
    object-fit: cover;
    object-position: center;
    position: relative;
  }
`

const navbar = css`
  height: 60px;
  padding-bottom: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #eee;
  position:fixed;
  width: 100%;
  top:0px;
  background: #fff;
  z-index: 111;
`

const navbarNav = css`
  flex-direction:row;
  &:first-of-type{
    margin-right:auto;
  }
  &:last-of-type{
    margin-left:auto;
  }
  li{
    display:inline-flex;
    margin-right: 15px;
    &:last-of-type{
      margin-right:0px;
    }
    a{
      color: #212529 !important;
    }
  }
`

const revenuePlace = css`
  background: rgb(251, 251, 251);
`

const becomeDesigner = css`
  background: #1181eb;
  color: #fff;
`

const contentStep = css`
  span{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    box-shadow:0 2px 6px 0 rgba(255, 255, 255,0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    color: #292929;
    margin-bottom: 20px;
  }
`

const btnWhite = css`
  background: #fff;
  border-radius: 3px;
  color: #1181eb;
  width: auto;
  display: inline-flex;
`

const footer = css`
  background: #292929;
  color: #fff;
  ul{
    li{
      a{
        display:block;
        margin-top: 16px;
      }
    }
  }
`

const borderTop = css`
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 15px;
`

export default {
  background,
  contentWrapper,
  heroImage,
  browserWrapper,
  patternPlace,
  portfolio,
  navbarBrand,
  navbar,
  navbarNav,
  revenuePlace,
  becomeDesigner,
  contentStep,
  btnWhite,
  footer,
  borderTop,
}
