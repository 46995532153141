import { css } from 'emotion'

const background = css`
  background: #1181eb;
  margin-top: -200px;
  padding-top: 250px;
  color: #fff;
  h3{
    font-weight: bold;
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-top: -100px !important;
  }
`

const contentWrapper = css`
  button{
    width: auto;
    display: inline-flex;
    margin-top: 20px;
  }
  h1{
    font-weight: bold;
  }
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

const noPaddMobile = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-top: 0px !important;
  }
`

const heroImage = css`
  img{
    box-shadow: 0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    border-radius: 4px;
  }
`

const browserWrapper = css`
  position: relative;
  img{
    border-top-left-radius:0px !important;
    border-top-right-radius:0px !important;
  }
  > div {
    border-top-left-radius:4px;
    border-top-right-radius:4px;
    background: #edf1f5;
    width: 100%;
    height: auto;
    padding: 9px 7px;
    > div {
      width: 93%;
      height: 15px;
      background: #fff;
      border-radius: 2px;
      margin-left: 10px;
    }
    span{
      width: 10px;
      height: 10px;
      display: inline-flex;
      border-radius: 50%;
      margin-left: 3px;
      margin-right: 3px;
      &:first-of-type{
        margin-left: 0px;
        background: #FF6057;
      }
      &:nth-child(2){
        background: #FFC12E;
      }
      &:last-of-type{
        margin-right: 0px;
        background: #28CA40;
      }
    }
  }
`

const patternPlace = css`
  span{
    padding: 20px;
    border-radius: 2px;
    box-shadow:0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    color: #1181eb;
    display: inline-flex;
    background: #fff;
    position:relative;
    &:first-of-type{
      i{
        font-size: 10px;
      }
    }
    &:nth-child(2){
      bottom: -75px;
      right: -25px;
      i{
        font-size: 36px;
      }
    }
    &:nth-child(3){
      right: -75px;
      top: 25px;
      i{
        font-size: 16px;
      }
    }
    &:nth-child(4){
      left: -50px;
      top: 50px;
    }
    &:last-of-type{
      bottom: -100px;
      right: -75px;
    }
    i{
      font-size: 26px;
    }
  }
`

const portfolio = css`
  h3, h1{
    font-weight: bold;
  }
  img{
    box-shadow: 0 28px 56px rgba(0,0,0,0.08), 0 20px 20px rgba(0,0,0,0.06);
    border-radius: 4px;
  }
`

const navbarBrand = css`
  padding-top: 9.5px;
  padding-bottom: 9.5px;
  &:hover{
    text-decoration:none;
  }
  span{
    font-weight: bold;
    color: #1181eb;
    margin-left: 3px;
  }
  img{
    width:40px;
    object-fit: cover;
    object-position: center;
    position: relative;
  }
`

const navbar = css`
  height: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #eee;
  position:fixed;
  width: 100%;
  top:0px;
  background: #fff;
  z-index: 111;
  @media only screen and (min-width:0px) and (max-width:767px){
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
`

const navbarNav = css`
  flex-direction:row;
  &:first-of-type{
    margin-right:auto;
  }
  &:last-of-type{
    margin-left:auto;
  }
  li{
    display:inline-flex;
    margin-right: 15px;
    &:last-of-type{
      margin-right:0px;
    }
    a{
      color: #212529 !important;
    }
  }
`

const revenuePlace = css`
  background: rgb(251, 251, 251);
`

const becomeDesigner = css`
  background: #1181eb;
  color: #fff;
`

const contentStep = css`
  span{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    box-shadow:0 2px 6px 0 rgba(255, 255, 255,0.2);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 22px;
    color: #292929;
    margin-bottom: 20px;
  }
`

const btnWhite = css`
  background: #fff;
  border-radius: 3px;
  color: #1181eb;
  width: auto;
  display: inline-flex;
`

const footer = css`
  background: #292929;
  color: #fff;
  ul{
    li{
      a{
        display:flex;
        margin-top: 16px;
        align-items: center;
        &:hover{
          color:rgba(255,255,255,0.75);
          text-decoration: none;
        }
        span{
          font-size: 10px;
          padding: 0px 4px;
          background: rgba(243, 243, 243, 0.5);
          border-radius: 2px;
          display: inline-block;
          color: rgba(255, 255, 255, 0.8);
          margin-left: 10px;
          font-weight: bold;
          height: auto;
        }
      }
    }
  }
`

const borderTop = css`
  margin-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 15px;
`

const borderBottom = css`
  border-bottom: 1px solid #eee;
`

const w6 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w5 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w4 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w8 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w10 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w3 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const w2 = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const navbarCollapse = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    display: none !important;
  }
`

const additionalMargin = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-top: -100px;
  }
`

const marginMobile = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 40px;
  }
`

const noMarginMobile = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-top: 0px !important;
  }
`

const container = css`
  @media only screen and (min-width:0px) and (max-width:767px){
  }
`

const marginFooter = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    margin-bottom: 30px;
  }
`

const hamburgerMenu = css`
  @media only screen and (min-width:786px){
    display: none;
  }
  cursor: pointer;
  position: absolute;
  right: 20px;
  span{
    width: 25px;
    background: #1181eb;
    display: flex;
    height: 2px;
    border-radius: 1px;
    &:nth-child(2){
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
`

function menu(open = false){
  const openStyle = open ? `display: block;` 
  : 
  `display: none;`
  return css`
    @media only screen and (min-width:786px){
      display: none;
    }
    ${openStyle}
    padding-top: 15px;
    border-top: 1px solid #eee;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    ul{
      &:first-of-type{
        li{
          &:first-of-type{
            padding-top: 0px;
          }
          padding-bottom: 15px;
          a{
            color: #212529 !important;
            &:hover{
              color: #212529 !important;
            }
          }
        }
      }
      &:last-of-type{
        li{
          margin-right: 15px;
          a{
            color: #212529 !important;
            &:hover{
              color: #212529 !important;
            }
          }
        }
      }
    }
  `
}


const aboutPattern = css`
  @media only screen and (min-width:0px) and (max-width:767px){
    &:after{
      width: 35% !important;
      height: 250px !important;
    }
    &:before{
      width: 35% !important;
      height: 150px !important;
      top: 125px !important;
      right: 125px !important;
    }
  }
  &:after{
    background: rgb(251, 251, 251);
    content: '';
    position: absolute;
    width: 35%;
    height: 450px;
    display: inline-flex;
    top: 0;
    right: 0;
    border-bottom-left-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0,0.02);
  }
  &:before{
    background: rgb(17, 129, 235);
    content: '';
    position: absolute;
    width: 35%;
    height: 350px;
    display: inline-flex;
    top: 175px;
    right: 150px;
    z-index: 1;
    border-radius: 3px;
    box-shadow: 0 2px 6px 0 rgba(51,105,231,0.2);
  }
`

const team = css`
  background: rgb(251,251,251);
`

const teamMember = css`
  li{
    margin-bottom: 20px;
    img{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    > div{
      > div{
        h5{
          font-weight: bold;
          margin-bottom: 0px;
        }
        margin-left: 15px;
      }
    }
  }
`

export default {
  background,
  contentWrapper,
  heroImage,
  browserWrapper,
  patternPlace,
  portfolio,
  navbarBrand,
  navbar,
  navbarNav,
  revenuePlace,
  becomeDesigner,
  contentStep,
  btnWhite,
  footer,
  borderTop,
  borderBottom,
  w6,
  w5,
  w4,
  w8,
  w10,
  w3,
  w2,
  navbarCollapse,
  noPaddMobile,
  additionalMargin,
  marginMobile,
  noMarginMobile,
  container,
  marginFooter,
  hamburgerMenu,
  menu,
  aboutPattern,
  team,
  teamMember
}
